<template>
    <div class="questions-media-input d-flex justify-center flex-column flex-fill">
        <v-btn
            text
            block
            color="primary"
            @click="isPicker = true"
        >
            <w-icon value="ADD" left/>
            {{ $trans('Media') }}
        </v-btn>
        <small class="text-center">{{ $trans('questions.hints.add_media') }}</small>
        <media-table
            hide-default-actions
            hide-default-header
            hide-empty-text
            hide-size
            hide-author
            hide-date
            :picker.sync="isPicker"
            :selectable-types="mediaTypes"
            :shared-type="sharedType"
            :shared-uuid="sharedUuid"
            viewable
            editable
            class="flex-fill"
            @select="onSelectDocuments"
        />
    </div>
</template>

<script>
import mobileBreakpointChecker from '@/mixins/mobileBreakpointChecker'
import MediaTable from '@apps/media/components/MediaTable'

export default {
    name: 'QuestionsMediaInput',
    components: { MediaTable },
    mixins: [
        mobileBreakpointChecker
    ],
    props: {
        value: {
            type: Array,
            default: function () {
                return []
            }
        },
        sharedType: {
            type: String,
            required: true
        },
        sharedUuid: {
            type: String,
            required: true
        }
    },
    computed: {
        pickerPayload() {
            return {
                author: this.$auth.user.uuid
            }
        },
        mediaTypes() {
            return [
                'jpg',
                'jpeg',
                'png',
                'gif',
                'mp3',
                'youtube'
            ]
        }
    },
    data() {
        return {
            isPicker: false
        }
    },
    methods: {
        onSelectDocuments(collection) {
            const uuids = collection.map(o => o.uuid)
            this.$emit('input', uuids)
        }
    }
}
</script>

<style lang=scss>

</style>
