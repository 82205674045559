<template>
    <v-card
        class="pa-4"
    >
        <div class="d-flex align-center mb-2">
            <div v-if="item.title" class="text-truncate">
                <div class="font-weight-semibold text-truncate">{{ item.title }}</div>
                <div
                    class="caption text-truncate"
                >
                    <span>{{ $trans('Duration') }}:</span>
                    <span class="font-weight-semibold">
                        {{ item.options.duration | questDurationLabel }}
                    </span>
                </div>
                <div
                    class="caption text-truncate"
                >
                    <span>{{ $trans('Estimate') }}:</span>
                    <span class="font-weight-semibold">
                        {{ item.sets_sum_score | questScoresLabel }}
                    </span>
                </div>
            </div>
            <v-spacer/>
            <w-actions-menu
                :items="actions"
                :inline="!isMobile"
            />
        </div>
        <v-divider/>
        <w-fx-table
            :loading="loading"
            hide-default-header
            :headers="headers"
            :items.sync="item.sets"
            item-class="d-flex align-center"
            item-key="uuid"
            draggable
            row-hover
            @click:row="onEditSet"
            @dragged="onResortSets"
        >
            <template #empty>
                <v-btn
                    text
                    block
                    color="primary"
                    @click="isSetsDialog = true"
                >
                    <w-icon value="ADD" left/>
                    {{ $trans('Add question') }}
                </v-btn>
            </template>
            <template #item.title="{item}">
                <div class="d-flex align-center flex-fill text-truncate">
                    <div class="d-flex align-center flex-fill text-truncate">
                        <v-avatar
                            color="primary"
                            rounded
                            size="28"
                            class="v-avatar-light-bg primary--text mr-2"
                        >
                            <v-icon
                                small
                                color="primary"
                            >
                                {{ iconsTypes[item.type] }}
                            </v-icon>
                        </v-avatar>
                        <div class="text-truncate">{{ item.title }}</div>
                    </div>
                </div>
            </template>
            <template #item.score="{item}">
                {{ item.score | questScoresLabel }}
            </template>

            <template #item.actions="{item}">
                <w-actions-menu
                    :items="_itemMenu(item)"
                    inline-icon
                />
            </template>
        </w-fx-table>
        <w-dialog-drawer
            v-model="isSetsDialog"
            :disabled="loading"
            :title="setsDialogTitle"
            width="991"
        >
            <div class="pa-4">
                <questions-set-form
                    :key="`questions-set-form-${formKey}`"
                    :item="selectedItem"
                    :errors="errors"
                    @save="onSaveSet"
                    @update="onUpdateSet"
                />
            </div>
        </w-dialog-drawer>
        <w-aside-drawer
            v-model="isOptionsDialog"
            :title="$trans('Options')"
            :disabled="isOptionsDialogDisabled"
        >
            <div class="pa-4">
                <w-simple-form
                    v-if="item"
                    :fields="optionsFormFields"
                    :item="item.options"
                    @update="onUpdateQuestions"
                />
            </div>
        </w-aside-drawer>
    </v-card>
</template>

<script>
import { icons } from '@/apps/questions/utils'
import {
    createQuestionsSet,
    deleteQuestionsSet,
    getQuestionsSets, resortQuestionsSets,
    updateQuestions,
    updateQuestionsSet
} from '@apps/questions/api'
import QuestionsSetForm from '@apps/questions/components/QuestionsSetForm'
import { shareDocuments } from '@apps/media/api'
import mobileBreakpointChecker from '@/mixins/mobileBreakpointChecker'
import ConfirmationSets from '@/utils/ConfirmationSets'
import { integerValidator } from '@/utils/validation'
import questionsFiltersMixin from '@apps/questions/mixins/questionsFiltersMixin'

export default {
    name: 'QuestionsSets',
    mixins: [
        mobileBreakpointChecker,
        questionsFiltersMixin
    ],
    components: {
        QuestionsSetForm
    },
    props: {
        uuid: {
            type: String,
            default: null
        }
    },
    computed: {
        iconsTypes() {
            return icons
        },
        headers() {
            const headrs = [
                {
                    text: this.$trans('Question'),
                    value: 'title'
                }
            ]

            if(!this.isMobile) {
                headrs.push({
                    text: this.$trans('Scores'),
                    value: 'score',
                    width: 90
                })
            }

            headrs.push({
                value: 'actions',
                width: 60
            })

            return headrs
        },
        actions() {
            return [
                {
                    text: this.$trans('Add question'),
                    icon: 'ADD',
                    color: 'primary',
                    on: {
                        click: () => {
                            this.isSetsDialog = true
                        }
                    }
                },
                {
                    text: this.$trans('Options'),
                    icon: 'SETTINGS',
                    color: 'primary',
                    on: {
                        click: () => {
                            this.isOptionsDialog = true
                        }
                    }
                }
            ]
        },
        setsDialogTitle() {
            return this.selectedItem
                ? this.$trans('Edit question')
                : this.$trans('Add question')
        },
        optionsFormFields() {
            return [
                {
                    type: 'v-text-field',
                    name: 'duration',
                    props: {
                        outlined: true,
                        dense: true,
                        label: this.$trans('Duration'),
                        hint: this.$trans('questions.hints.duration_set'),
                        persistentHint: true,
                        prependInnerIcon: 'mdi-clock-fast',
                        suffix: this.$trans('minutes'),
                        rules: [
                            integerValidator
                        ]
                    },
                    filter: value => {
                        const check = [
                            '',
                            0,
                            '0',
                            null,
                            'null',
                            'undefined',
                            undefined,
                            false,
                            true
                        ]

                        if(check.includes(value)) return null

                        return Number(value)
                    }
                },
                {
                    type: 'v-checkbox',
                    name: 'skip_question',
                    props: {
                        dense: true,
                        // hideDetails: true,
                        label: this.$trans('questions.hints.skip_question_set'),
                        messages: this.$trans('questions.hints.skip_question_set_hint')
                    }
                }
            ]
        }
    },
    watch: {
        isSetsDialog(state) {
            if(!state) {
                this.formKey++
                this.selectedItem = null
            }
        }
    },
    data() {
        return {
            loading: false,
            isSetsDialog: false,
            isOptionsDialog: false,
            isOptionsDialogDisabled: false,
            formKey: 0,
            defaultOptions: {
                duration: 0
            },
            item: {
                title: null,
                sets: []
            },
            errors: {},
            sharedType: 'questions_set',
            selectedItem: null
        }
    },
    mounted() {
        this.fetchItem()
    },
    methods: {
        _itemMenu(item) {
            return [
                {
                    text: this.$trans('Edit'),
                    icon: 'EDIT',
                    color: 'primary',
                    on: {
                        click: () => {
                            this.selectedItem = item
                            this.isSetsDialog = true
                        }
                    }
                },
                {
                    text: this.$trans('Delete'),
                    icon: 'CLOSE',
                    color: 'error',
                    on: {
                        click: () => {
                            this.onDeleteSet(item)
                        }
                    }
                }
            ]
        },
        async fetchItem() {
            this.loading = true

            try {
                const response = await getQuestionsSets(this.uuid)
                this.item = response.data.data

                this.$emit('update:item', this.item)
            } finally {
                this.loading = false
            }
        },
        async onUpdateQuestions(options) {
            this.isOptionsDialogDisabled = true

            try {
                const payload = {
                    options,
                    uuid: this.uuid
                }
                await updateQuestions(payload)

                this.isOptionsDialog = false

                await this.fetchItem()
            } catch (error) {
                // ...
            } finally {
                this.isOptionsDialogDisabled = false
            }
        },
        onEditSet(item) {
            this.selectedItem = item
            this.isSetsDialog = true
        },
        async onSaveSet(set) {
            this.loading = true

            const media = set.media

            try {
                const response = await createQuestionsSet({
                    questions_uuid: this.uuid,
                    ...set
                })

                const questionsSet = response.data.data

                if(media && media.length > 0) {
                    const shareMedia = []

                    media.forEach(media_uuid => {
                        shareMedia.push({
                            shared_type: this.sharedType,
                            shared_uuid: questionsSet.uuid,
                            media_uuid
                        })
                    })

                    await shareDocuments(shareMedia)
                }

                this.isSetsDialog = false

                await this.fetchItem()
            } catch (error) {
                // if(error.response.data.errors) {
                //     this.errors = error.response.data.errors
                // }
            } finally {
                this.loading = false
            }
        },
        async onUpdateSet(set) {
            this.loading = true

            try {
                await updateQuestionsSet(set.uuid, set)

                this.isSetsDialog = false

                await this.fetchItem()
            } catch (error) {
                // if(error.response.data.errors) {
                //     this.errors = error.response.data.errors
                // }
            } finally {
                this.loading = false
            }
        },
        async onResortSets(data) {
            this.loading = true

            const items = data.map(o => ({
                uuid: o.uuid,
                weight: Number(o.weight)
            }))

            try {
                this.$notification.disabled()
                await resortQuestionsSets({ items })
            } catch (error) {
                // if(error.response.data.errors) {
                //     this.errors = error.response.data.errors
                // }
            } finally {
                this.loading = false
            }
        },
        async onDeleteSet(item) {
            const isConfirm = await this.$confirm(ConfirmationSets.deleteAndCannotUndoneSet({
                title: `${ this.$trans('Delete') } ${ item.title }`
            }))

            if(!isConfirm) {
                return
            }

            this.loading = true

            try {
                await deleteQuestionsSet(item.uuid)
                await this.fetchItem()
            } catch (error) {
            } finally {
                this.loading = false
            }
        }
    }
}
</script>

<style lang=scss>

</style>
