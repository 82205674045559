<template>
    <v-form
        class="questions-set-form"
        ref="wSimpleForm"
        v-model="isValid"
        lazy-validation
    >
        <div class="theme-outline px-4 pt-1">
            <v-text-field
                v-model="formData.title"
                :label="$trans('Your question')"
                counter="170"
                :rules="[rules.required]"
            />
        </div>
        <quill-editor
            v-model="formData.description"
            :options="editorOptions"
            class="theme-outline mt-4"
            :class="isEditorFocused ? 'quill-editor--focused' : ''"
            @blur="onEditorBlur($event)"
            @focus="onEditorFocus($event)"
        />
        <div class="mt-4 pa-2 theme-outline d-flex justify-center">
            <questions-media-input
                v-model="formData.media"
                :shared-uuid="isEditing ? item.uuid : '#'"
                shared-type="questions_set"
            />
        </div>
        <div
            :class="`mt-4 theme-outline theme-outline--${typeSetHintState} pa-4`"
        >
            <div class="d-flex align-center mb-4">
                <div class="flex-fill">
                    <questions-type-select
                        v-model="formData.type"
                        @input="onTypeSelect"
                    />
                </div>
                <div class="pl-3" style="max-width: 30%">
                    <v-select
                        v-model="formData.score"
                        :items="scoresItems"
                        outlined
                        hide-details
                        :label="$trans('questions.hints.scores_of_answer')"
                    />
                </div>
            </div>
            <questions-type-input
                v-model="formData.options"
                :type="formData.type"
            >
                <small
                    :class="`${typeSetHintState}--text`"
                >
                    {{ typeSetHint }}
                </small>
            </questions-type-input>
        </div>
        <div class="d-flex align-center mt-4">
            <v-spacer/>
            <v-btn
                :disabled="!isValid || typeSetHintState === 'error'"
                outlined
                color="primary"
                depressed
                @click="onSubmit"
            >
                {{ action.text }}
            </v-btn>
        </div>
    </v-form>
</template>

<script>
import QuestionsTypeSelect from '@apps/questions/components/QuestionsType/QuestionsTypeSelect'
import QuestionsTypeInput from '@apps/questions/components/QuestionsType/QuestionsTypeInput'
import hasWSimpleFormHandlers from '@/widgets/components/WSimpleForm/mixins/hasWSimpleFormHandlers'
import { required, integerValidator } from '@/utils/validation'
import QuestionsMediaInput from '@apps/questions/components/QuestionsSetForm/QuestionsMediaInput'
export default {
    name: 'QuestionsSetForm',
    mixins: [
        hasWSimpleFormHandlers
    ],
    components: { QuestionsMediaInput, QuestionsTypeInput, QuestionsTypeSelect },
    computed: {
        rules() {
            return {
                required,
                integerValidator
            }
        },
        isInputCorrect() {
            return !!this.formData.options.correct
        },
        typeSetHint() {
            const path = `questions.hints.${this.formData.type}_set`
            return this.$trans(path)
        },
        scoresItems() {
            const items = []

            for (let i = 0; i < 12; i++) {
                items.push(`${i + 1}`)
            }

            return items
        }
    },
    watch: {
        isInputCorrect(value) {
            if(value) {
                this.typeSetHintState = 'normal'
            }
        }
    },
    data() {
        return {
            typeSetHintState: 'normal',
            editorOptions: {
                // theme: 'bubble',
                modules: {
                    toolbar: [
                        [ 'bold', 'italic', 'underline', 'link' ],
                        [ 'blockquote', 'code-block' ],
                        [ 'clean' ]
                    ]
                },
                placeholder: this.$trans('questions.hints.description'),
                readOnly: false
            },
            isEditorFocused: false,
            formData: {
                title: null,
                description: null,
                media: [],
                type: 'true_false',
                score: 1,
                options: {
                    correct: null
                }
            }
        }
    },
    methods: {
        formValidation() {
            this.typeSetHintState = !this.isInputCorrect
                ? 'error'
                : 'normal'

            return this.isInputCorrect
        },
        onTypeSelect() {
            this.typeSetHintState = 'normal'

            this.formData.options = {
                correct: null
            }
        },
        onEditorBlur(quill) {
            this.isEditorFocused = false
        },
        onEditorFocus(quill) {
            this.isEditorFocused = true
        },
    }
}
</script>

<style lang=scss>
    .questions-set-form {
        .quill-editor {
            position: relative;
            transition: all .2s;

            //&:before {
            //    content: "";
            //    position: absolute;
            //    left: 0;
            //    bottom: -1px;
            //    width: 0;
            //    height: 2px;
            //    background-color: var(--v-primary-base);
            //    transition: all .2s;
            //    z-index: 0;
            //}

            &--focused {
                border-color: var(--v-primary-base);
                &:before {
                    width: 100%;
                }
            }
        }

        //.ql-editor {
        //    padding-left: 0;
        //    padding-right: 0;
        //}
    }
</style>
