<template>
    <v-select
        :value="value"
        :items="typesItems"
        item-value="type"
        outlined
        hide-details
        @change="onChange"
    >
        <template #selection="{item}">
            <div class="text-truncate">
                <v-avatar
                    color="primary"
                    rounded
                    size="28"
                    class="v-avatar-light-bg primary--text mr-2"
                >
                    <v-icon
                        small
                        color="primary"
                    >
                        {{ item.icon }}
                    </v-icon>
                </v-avatar>
                <span class="text-truncate">{{ item.text }}</span>
            </div>
        </template>

        <template #item="{item}">
            <div class="py-1">
                <v-avatar
                    color="primary"
                    rounded
                    size="28"
                    class="v-avatar-light-bg primary--text mr-2"
                >
                    <v-icon
                        small
                        color="primary"
                    >
                        {{ item.icon }}
                    </v-icon>
                </v-avatar>
                <span class="text-truncate">{{ item.text }}</span>
            </div>
        </template>
    </v-select>
</template>

<script>
import { types, icons } from '@/apps/questions/utils'

export default {
    name: 'QuestionsTypeSelect',
    props: {
        value: String
    },
    computed: {
        currentType() {
            return this.typesItems
                .find(o => o.index === this.currentTypeIndex)
        },
        typesItems() {
            const items = []

            types.forEach((type, index) => {
                items.push({
                    index,
                    type,
                    icon: icons[type],
                    color: '#546E7A',
                    text: this.$trans('questions.types.' + type)
                })
            })

            return items
        }
    },
    methods: {
        onChange(type) {
            this.$emit('input', type)
        }
    }
}
</script>

<style lang=scss>

</style>
