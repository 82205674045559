<template>
    <content-wrapper>
        <questions-sets
            :uuid="uuid"
            @update:item="item => {title = item.title}"
        />
    </content-wrapper>
</template>

<script>
import ContentWrapper from '@/components/ContentWrapper'
import QuestionsSets from '@apps/questions/components/QuestionsSets'

export default {
    name: 'QuestionsSetsPage',
    components: {
        QuestionsSets,
        ContentWrapper
    },
    props: {
        uuid: {
            type: String,
            default: null
        }
    },
    computed: {
        breadcrumbs() {
            return [
                {
                    text: this.$trans('Tests'),
                    to: { name: 'questions.manage' }
                },
                {
                    text: this.title
                }
            ]
        }
    },
    data() {
        return {
            title: null
        }
    }
}
</script>

<style lang=scss>

</style>
